<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="6"
                lg="6"
                md="6"
                sm="6"
            >
                <h3>Color Selection</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <hr />
            </v-col>
        </v-row>
                    
        <JobInformation 
            :id="serviceRequest.id"
            :project="projectSelected"
            :refreshJob="refreshJob"
            :showJobSummary="true"
            :showButtonsCreateEditProject="false"
            :showButtonsCreateEditVersion="false"
            v-on:changeProject="updateProject"
        />

        <v-row 
            v-if="projectSelected != null && projectSelected != undefined"
            justify="center"
        >
            <v-col
                v-if="showHideButtonSections"
                style="text-align:right;"
                cols="12"
            >
                <v-tooltip top v-if="!showButtonEyesSections">
                    <template v-slot:activator="{ on }">
                        <v-icon large v-on="on" @click="showButtonEyesSections = true; showAllSections();"  v-if="!showButtonEyesSections">mdi-eye-off</v-icon>
                    </template>
                    <span>Show all sections</span>
                </v-tooltip>
                <v-tooltip top v-if="showButtonEyesSections">
                    <template v-slot:activator="{ on }">
                        <v-icon large v-on="on" @click="showButtonEyesSections = false; getColorSelection();" v-if="showButtonEyesSections">mdi-eye</v-icon>
                    </template>
                    <span>Hide sections</span>
                </v-tooltip>
            </v-col>

            <v-expansion-panels
                popout
                v-model="panel"
            >
                <!-- POOL COPING SELECTION -->
                <v-col
                    v-if="isPool && showSections.poolCoping"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listCoping)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Pool Coping Selection
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Item #</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Texture</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Pattern Choice</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row
                                v-for="(item, index) in colorSelectionRequest.listCoping" :key="index" 
                                style="height: 60px;" 
                            >
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.item" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.texture" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                >
                                    <v-select
                                        v-model="item.patternSelected"
                                        :items="listPattern"
                                        outlined
                                        dense
                                        chips
                                        :disabled="!canEdit"
                                    >
                                        <template v-slot:selection="{ item, index }">
                                            <v-chip small :key="index">{{ item.description }}</v-chip>
                                        </template>
                                        <template v-slot:item="{ active, item, attrs, on, index }" >
                                            <v-list-item v-on="on" v-bind="attrs" :default="{ active }" :key="index">
                                                <v-list-item-content :key="index">
                                                    <v-list-item-title :key="index">
                                                        <v-row 
                                                            no-gutters 
                                                            align="center" 
                                                            :key="index"
                                                        >
                                                            <span :key="index">{{ item.description }}</span>
                                                            <v-spacer :key="index"></v-spacer>
                                                            <img :key="index" :src="require('@/assets/images/' + item.image)" style="width: 70px;" />
                                                        </v-row>
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                        @blur="$forceUpdate()"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="1"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listCoping)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listCoping)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listCoping.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- SPA COPING SELECTION -->
                <v-col
                    v-if="isPool && showSections.spaCoping"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listSpa)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Spa Coping Selection
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Item #</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Texture</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Pattern Choice</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listSpa" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.item" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.texture" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                >
                                    <v-select
                                        v-model="item.patternSelected"
                                        :items="listPattern"
                                        outlined
                                        dense
                                        chips
                                        :disabled="!canEdit"
                                    >
                                        <template v-slot:selection="{ item, index }">
                                            <v-chip small :key="index">{{ item.description }}</v-chip>
                                        </template>
                                        <template v-slot:item="{ active, item, attrs, on, index }" >
                                            <v-list-item v-on="on" v-bind="attrs" :default="{ active }" :key="index">
                                                <v-list-item-content :key="index">
                                                    <v-list-item-title :key="index">
                                                        <v-row 
                                                            no-gutters 
                                                            align="center" 
                                                            :key="index"
                                                        >
                                                            <span :key="index">{{ item.description }}</span>
                                                            <v-spacer :key="index"></v-spacer>
                                                            <img :key="index" :src="require('@/assets/images/' + item.image)" style="width: 70px;" />
                                                        </v-row>
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="1"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listSpa)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listSpa)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listSpa.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- POOL WATERLINE -->
                <v-col
                    v-if="isPool && showSections.poolWaterline"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listPoolWaterline)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Pool Waterline
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Item #</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listPoolWaterline" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.item" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listPoolWaterline)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>2</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listPoolWaterline)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listPoolWaterline.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- POOL CAP TILE -->
                <v-col
                    v-if="isPool && showSections.poolCapTile"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listPoolCapTile)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Pool Cap Tile
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Item #</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listPoolCapTile" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.item" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listPoolCapTile)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span small>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listPoolCapTile)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listPoolCapTile.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- SPA WATERLINE -->
                <v-col
                    v-if="isPool && showSections.spaWaterline"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listSpaWaterline)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Spa Waterline
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Item #</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listSpaWaterline" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.item" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listSpaWaterline)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listSpaWaterline)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listSpaWaterline.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- SPA CAP TILE -->
                <v-col
                    v-if="isPool && showSections.spaCapTile"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listSpaCapTile)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Spa Cap Tile
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Item #</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listSpaCapTile" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.item" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listSpaCapTile)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listSpaCapTile)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listSpaCapTile.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- SPA EXTERIOR WALL -->
                <v-col
                    v-if="isPool && showSections.spaExteriorWall"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listSpaExteriorWall)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Spa Exterior Wall
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Item #</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listSpaExteriorWall" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.item" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listSpaExteriorWall)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listSpaExteriorWall)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listSpaExteriorWall.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- SPA SPILLWAY -->
                <v-col
                    v-if="isPool && showSections.spaSpillWay"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listSpaSpillway)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Spa Spillway
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Item #</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listSpaSpillway" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.item" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listSpaSpillway)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listSpaSpillway)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listSpaSpillway.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- TROUGH TILE -->
                <v-col
                    v-if="isPool && showSections.troughTile"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listTroughTile)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Trough Tile
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Item #</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listTroughTile" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.item" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listTroughTile)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listTroughTile)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listTroughTile.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- FRONT OF RAISED WALL -->
                <v-col
                    v-if="isPool && showSections.frontRaisedWall"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listFrontRaisedWall)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Front of Raised Wall
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Item #</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listFrontRaisedWall" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.item" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listFrontRaisedWall)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listFrontRaisedWall)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listFrontRaisedWall.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- SIDES OF RAISED WALL -->
                <v-col
                    v-if="isPool && showSections.sidesRaisedWall"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listSidesRaisedWall)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Sides of Raised Wall
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Item #</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listSidesRaisedWall" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.item" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listSidesRaisedWall)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listSidesRaisedWall)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listSidesRaisedWall.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- BACK RAISED WALL -->
                <v-col
                    v-if="isPool && showSections.backRaisedWall"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listBackRaisedWall)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Back of Raised Wall
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Item #</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listBackRaisedWall" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.item" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listBackRaisedWall)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listBackRaisedWall)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listBackRaisedWall.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- FRONT OF PEDESTAL -->
                <v-col
                    v-if="isPool && showSections.frontPedestal"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listFrontPedestal)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Front of Pedestal
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Item #</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listFrontPedestal" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.item" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listFrontPedestal)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listFrontPedestal)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listFrontPedestal.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- SIDES OF PEDESTAL -->
                <v-col
                    v-if="isPool && showSections.sidesPedestal"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listSidesPedestal)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Sides of Pedestal
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Item #</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listSidesPedestal" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.item" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listSidesPedestal)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listSidesPedestal)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listSidesPedestal.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- BACK OF PEDESTAL -->
                <v-col
                    v-if="isPool && showSections.backPedestal"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listBackPedestal)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Back of Pedestal
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Item #</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listBackPedestal" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.item" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listBackPedestal)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listBackPedestal)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listBackPedestal.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- TOP OF PEDESTAL -->
                <v-col
                    v-if="isPool && showSections.topPedestal"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listTopPedestal)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Top of Pedestal
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Item #</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listTopPedestal" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.item" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listTopPedestal)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listTopPedestal)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listTopPedestal.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- TILE INSERTS -->
                <v-col
                    v-if="isPool && showSections.tileInserts"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listTileInserts)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Tile Inserts
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Item #</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Supplier</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Install Location</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listTileInserts" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.item" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.supplier" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.installLocation" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listTileInserts)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listTileInserts)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listTileInserts.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- MOSAICS -->
                <v-col
                    v-if="isPool && showSections.mosaics"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listMosaics)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Mosaics
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Item #</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Supplier</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Qty</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Install Location</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listMosaics" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.item" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.supplier" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.qty" 
                                        :rules=[validations.required]
                                        v-mask="'####'"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.installLocation" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listMosaics)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listMosaics)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listMosaics.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- SCUPPERS -->
                <v-col
                    v-if="isPool && showSections.scuppers"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listScuppers)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Scuppers
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Item #</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Supplier</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Qty</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listScuppers" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.item" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.supplier" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.qty" 
                                        :rules=[validations.required]
                                        v-mask="'####'"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listScuppers)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listScuppers)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listScuppers.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- TABLE TOP -->
                <v-col
                    v-if="isPool && showSections.tableTop"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listTableTop)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Table Top
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listTableTop" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listTableTop)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listTableTop)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listTableTop.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- TABLE SIDES -->
                <v-col
                    v-if="isPool && showSections.tableSides"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listTableSides)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Table Sides
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listTableSides" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listTableSides)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listTableSides)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listTableSides.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- TABLE BASE -->
                <v-col
                    v-if="isPool && showSections.tableBase"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listTableBase)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Table Base
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listTableBase" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listTableBase)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listTableBase)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listTableBase.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- BAR STOOLS TOP -->
                <v-col
                    v-if="isPool && showSections.barStoolTop"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listBarStoolsTop)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Bar Stools Top
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listBarStoolsTop" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listBarStoolsTop)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listBarStoolsTop)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listBarStoolsTop.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- BAR STOOLS SIDES -->
                <v-col
                    v-if="isPool && showSections.barStoolSides"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listBarStoolsSides)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Bar Stools Sides
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listBarStoolsSides" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listBarStoolsSides)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listBarStoolsSides)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listBarStoolsSides.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- BAR STOOLS BASE -->
                <v-col
                    v-if="isPool && showSections.barStoolBase"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listBarStoolsBase)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Bar Stools Base
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listBarStoolsBase" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listBarStoolsBase)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listBarStoolsBase)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listBarStoolsBase.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- HANDHOLD SELECTION -->
                <v-col
                    v-if="isPool && showSections.handHold"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listHandholdSelection)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Handhold Selection
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Item #</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Supplier</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="3"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listHandholdSelection" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.item" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.supplier" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="3"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listHandholdSelection)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listHandholdSelection)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listHandholdSelection.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- WATERFALL COLOR -->
                <v-col
                    v-if="isPool && showSections.waterFallColor"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listWaterfallColor)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Waterfall Color
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Gray</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Keystone</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Brown</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listWaterfallColor" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.gray" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.keystone" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.brown" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listWaterfallColor)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listWaterfallColor)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listWaterfallColor.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- FOUR PIECE DECK -->
                <v-col
                    v-if="isPool && showSections.fourPieceDeck"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listFourPieceDeck)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Four Piece Deck
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Texture</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Pattern Choices</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listFourPieceDeck" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>

                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.texture" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                >
                                    <v-select
                                        v-model="item.patternSelected"
                                        :items="listFourPiecePattern"
                                        outlined
                                        dense
                                        chips
                                        :disabled="!canEdit"
                                    >
                                        <template v-slot:selection="{ item, index }">
                                            <v-chip small :key="index">{{ item.description }}</v-chip>
                                        </template>
                                        <template v-slot:item="{ active, item, attrs, on, index }" >
                                            <v-list-item v-on="on" v-bind="attrs" :default="{ active }" :key="index">
                                                <v-list-item-content :key="index">
                                                    <v-list-item-title :key="index">
                                                        <v-row 
                                                            no-gutters 
                                                            align="center" 
                                                            :key="index"
                                                        >
                                                            <span :key="index">{{ item.description }}</span>
                                                            <v-spacer :key="index"></v-spacer>
                                                            <img :key="index" :src="require('@/assets/images/' + item.image)" style="width: 70px;" />
                                                        </v-row>
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listFourPieceDeck)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listFourPieceDeck)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listFourPieceDeck.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- 6x12 DECK -->
                <v-col
                    v-if="isPool && showSections.deck6x12"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listDeck6x12)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    6x12 Deck
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Texture</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Pattern Choices</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listDeck6x12" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>

                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.texture" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                >
                                    <v-select
                                        v-model="item.patternSelected"
                                        :items="list6x12Pattern"
                                        outlined
                                        dense
                                        chips
                                        :disabled="!canEdit"
                                    >
                                        <template v-slot:selection="{ item, index }">
                                            <v-chip small :key="index">{{ item.description }}</v-chip>
                                        </template>
                                        <template v-slot:item="{ active, item, attrs, on, index }" >
                                            <v-list-item v-on="on" v-bind="attrs" :default="{ active }" :key="index">
                                                <v-list-item-content :key="index">
                                                    <v-list-item-title :key="index">
                                                        <v-row 
                                                            no-gutters 
                                                            align="center" 
                                                            :key="index"
                                                        >
                                                            <span :key="index">{{ item.description }}</span>
                                                            <v-spacer :key="index"></v-spacer>
                                                            <img :key="index" :src="require('@/assets/images/' + item.image)" style="width: 70px;" />
                                                        </v-row>
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listDeck6x12)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listDeck6x12)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listDeck6x12.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- 12x24 DECK -->
                <v-col
                    v-if="isPool && showSections.deck12x24"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listDeck12x24)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    12x24 Deck
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Texture</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Pattern Choices</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listDeck12x24" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.texture" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                >
                                    <v-select
                                        v-model="item.patternSelected"
                                        :items="list12x24And16x24Pattern"
                                        outlined
                                        dense
                                        chips
                                        :disabled="!canEdit"
                                    >
                                        <template v-slot:selection="{ item, index }">
                                            <v-chip small :key="index">{{ item.description }}</v-chip>
                                        </template>
                                        <template v-slot:item="{ active, item, attrs, on, index }" >
                                            <v-list-item v-on="on" v-bind="attrs" :default="{ active }" :key="index">
                                                <v-list-item-content :key="index">
                                                    <v-list-item-title :key="index">
                                                        <v-row 
                                                            no-gutters 
                                                            align="center" 
                                                            :key="index"
                                                        >
                                                            <span :key="index">{{ item.description }}</span>
                                                            <v-spacer :key="index"></v-spacer>
                                                            <img :key="index" :src="require('@/assets/images/' + item.image)" style="width: 70px;" />
                                                        </v-row>
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listDeck12x24)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listDeck12x24)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listDeck12x24.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- 16x24 DECK -->
                <v-col
                    v-if="isPool && showSections.deck16x24"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listDeck16x24)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    16x24 Deck
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Texture</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Pattern Choices</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listDeck16x24" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>

                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.texture" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                >
                                    <v-select
                                        v-model="item.patternSelected"
                                        :items="list12x24And16x24Pattern"
                                        outlined
                                        dense
                                        chips
                                        :disabled="!canEdit"
                                    >
                                        <template v-slot:selection="{ item, index }">
                                            <v-chip small :key="index">{{ item.description }}</v-chip>
                                        </template>
                                        <template v-slot:item="{ active, item, attrs, on, index }" >
                                            <v-list-item v-on="on" v-bind="attrs" :default="{ active }" :key="index">
                                                <v-list-item-content :key="index">
                                                    <v-list-item-title :key="index">
                                                        <v-row 
                                                            no-gutters 
                                                            align="center" 
                                                            :key="index"
                                                        >
                                                            <span :key="index">{{ item.description }}</span>
                                                            <v-spacer :key="index"></v-spacer>
                                                            <img :key="index" :src="require('@/assets/images/' + item.image)" style="width: 70px;" />
                                                        </v-row>
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listDeck16x24)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listDeck16x24)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listDeck16x24.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- RETAINING WALL BACK MATERIAL -->
                <v-col
                    v-if="isPool && showSections.retainingWallBackMaterial"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listRetainingWallBackMaterial)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Retaining Wall Back Material
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="4"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Texture</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listRetainingWallBackMaterial" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="4"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.texture" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listRetainingWallBackMaterial)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listRetainingWallBackMaterial)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listRetainingWallBackMaterial.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- INTERIOR POOL FINISH -->
                <v-col
                    v-if="isPool && showSections.interiorPoolFinish"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listInteriorPoolFinish)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Interior Pool Finish
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Supplier</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listInteriorPoolFinish" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.type" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.supplier" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listInteriorPoolFinish)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listInteriorPoolFinish)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listInteriorPoolFinish.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- COLUMNS SIDES -->
                <v-col
                    v-if="isPool && showSections.columnSides"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listColumnsSides)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Column Sides
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Finish</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listColumnsSides" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.finish" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listColumnsSides)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listColumnsSides)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listColumnsSides.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- COLUMNS TOP -->
                <v-col
                    v-if="isPool && showSections.columnTop"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listColumnsTop)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Column Top
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Finish</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listColumnsTop" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.finish" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listColumnsTop)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listColumnsTop)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listColumnsTop.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- FIRE PIT TOP MATERIAL -->
                <v-col
                    v-if="isPool && showSections.firePitTopMaterial"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listFirePitTopMaterial)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Fire Pit Top Material
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Item #</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listFirePitTopMaterial" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.item" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listFirePitTopMaterial)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listFirePitTopMaterial)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listFirePitTopMaterial.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- FIRE PIT SIDES MATERIAL -->
                <v-col
                    v-if="isPool && showSections.firePitSidesMaterial"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listFirePitSidesMaterial)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Fire Pit Sides Material
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Item #</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listFirePitSidesMaterial" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.item" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listFirePitSidesMaterial)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listFirePitSidesMaterial)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listFirePitSidesMaterial.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- FIRE PIT BACK MATERIAL -->
                <v-col
                    v-if="isPool && showSections.firePitBackMaterial"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listFirePitBackMaterial)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Fire Pit Back Material
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Item #</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listFirePitBackMaterial" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.item" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listFirePitBackMaterial)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listFirePitBackMaterial)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listFirePitBackMaterial.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- FIRE PIT FRONT MATERIAL -->
                <v-col
                    v-if="isPool && showSections.firePitFrontMaterial"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listFirePitFrontMaterial)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Fire Pit Front Material
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Item #</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listFirePitFrontMaterial" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.item" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listFirePitFrontMaterial)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listFirePitFrontMaterial)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listFirePitFrontMaterial.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- BBQ TOP MATERIAL -->
                <v-col
                    v-if="isBBQ && showSections.bbqTopMaterial"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listBBQTopMaterial)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    BBQ Top Material
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Item #</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listBBQTopMaterial" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.item" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listBBQTopMaterial)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listBBQTopMaterial)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listBBQTopMaterial.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- BBQ SIDES MATERIAL -->
                <v-col
                    v-if="isBBQ && showSections.bbqSidesMaterial"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listBBQSidesMaterial)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    BBQ Sides Material
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Item #</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listBBQSidesMaterial" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.item" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listBBQSidesMaterial)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listBBQSidesMaterial)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listBBQSidesMaterial.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- BBQ BACK MATERIAL -->
                <v-col
                    v-if="isBBQ && showSections.bbqBackMaterial"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listBBQBackMaterial)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    BBQ Back Material
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Item #</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listBBQBackMaterial" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.item" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>

                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listBBQBackMaterial)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listBBQBackMaterial)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listBBQBackMaterial.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- BBQ FRONT MATERIAL -->
                <v-col
                    v-if="isBBQ && showSections.bbqFrontMaterial"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listBBQFrontMaterial)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    BBQ Front Material
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Item #</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listBBQFrontMaterial" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.item" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listBBQFrontMaterial)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listBBQFrontMaterial)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listBBQFrontMaterial.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- BBQ BLACK SPLASH MATERIAL -->
                <v-col
                    v-if="isBBQ && showSections.bbqBlackSplashMaterial"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listBBQBlackSplashMaterial)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    BBQ Black Splash Material
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Item #</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Size</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Grout Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listBBQBlackSplashMaterial" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.item" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.size" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutType" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.groutColor" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listBBQBlackSplashMaterial)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listBBQBlackSplashMaterial)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listBBQBlackSplashMaterial.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- BOWLS COLOR -->
                <v-col
                    v-if="isPool && showSections.bowlsColor"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listBowlsColor)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Bowls Color
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Supplier</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Item #</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listBowlsColor" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.supplier" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.item" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listBowlsColor)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listBowlsColor)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listBowlsColor.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- BABY FENCE -->
                <v-col
                    v-if="isPool && showSections.babyFence"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listBabyFence)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Baby Fence
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="4"
                                    lg="4"
                                    md="4"
                                    sm="4"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="3"
                                    lg="3"
                                    md="3"
                                    sm="3"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="3"
                                    lg="3"
                                    md="3"
                                    sm="3"
                                >
                                    <label>Color</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listBabyFence" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="4"
                                    lg="4"
                                    md="4"
                                    sm="4"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="3"
                                    lg="3"
                                    md="3"
                                    sm="3"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="3"
                                    lg="3"
                                    md="3"
                                    sm="3"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listBabyFence)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listBabyFence)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listBabyFence.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- PERIMETER FENCE -->
                <v-col
                    v-if="isPool && showSections.perimiterFence"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listPerimeterFence)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Perimeter Fence
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="4"
                                    lg="4"
                                    md="4"
                                    sm="4"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="3"
                                    lg="3"
                                    md="3"
                                    sm="3"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="3"
                                    lg="3"
                                    md="3"
                                    sm="3"
                                >
                                    <label>Color</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listPerimeterFence" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="4"
                                    lg="4"
                                    md="4"
                                    sm="4"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="3"
                                    lg="3"
                                    md="3"
                                    sm="3"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="3"
                                    lg="3"
                                    md="3"
                                    sm="3"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listPerimeterFence)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listPerimeterFence)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listPerimeterFence.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- PERGOLA -->
                <v-col
                    v-if="isPergola && showSections.pergola"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listPergola)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Pergola
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="4"
                                    lg="4"
                                    md="4"
                                    sm="4"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="3"
                                    lg="3"
                                    md="3"
                                    sm="3"
                                >
                                    <label>Material Type</label>
                                </v-col>
                                <v-col 
                                    cols="3"
                                    lg="3"
                                    md="3"
                                    sm="3"
                                >
                                    <label>Color</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listPergola" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="4"
                                    lg="4"
                                    md="4"
                                    sm="4"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="3"
                                    lg="3"
                                    md="3"
                                    sm="3"
                                >
                                    <v-combobox
                                        v-model="item.materialTypeSelected"
                                        :items="listCSMaterialType"
                                        item-text="description"
                                        item-value="id"
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="3"
                                    lg="3"
                                    md="3"
                                    sm="3"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listPergola)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listPergola)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listPergola.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- FITTING COLOR -->
                <v-col
                    v-if="isPool && showSections.fittingColor"
                    style="padding-top: 10px !important;"
                    cols="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon :color="setColor(colorSelectionRequest.listFittingColor)" left>
                                        mdi-file-document-check-outline
                                    </v-icon>
                                    Fitting Color
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row style="height: 25px;">
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Item #</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="3"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Product</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Color</label>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Upgrade</label>
                                </v-col>
                            </v-row>

                            <v-row v-for="(item, index) in colorSelectionRequest.listFittingColor" :key="index" style="height: 60px;" >

                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.item" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="3"
                                    md="4"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.productSelected"
                                        :items="listCSProducts"
                                        item-text="description"
                                        item-value="id"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                        :disabled="!canEdit"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.color" 
                                        :rules=[validations.required]
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-text-field
                                        v-model="item.upgrade" 
                                        :rules=[validations.required]
                                        v-formatMoney="moneyFormat"
                                        suffix="$"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="!canEdit || !canEditPrice(item)"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col 
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewItem(item, colorSelectionRequest.listFittingColor)" 
                                                color="blue"
                                                :key="index"
                                                :disabled="!canEdit"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteItem(index, colorSelectionRequest.listFittingColor)" 
                                                color="red"
                                                :disabled="colorSelectionRequest.listFittingColor.length <= 1 && !canEdit"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                :style="item.note != null && item.note != undefined && item.note != '' ? '' : 'color: gray !important;'"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="showItemNote(item)"
                                            >
                                                <v-icon small>mdi-message-bulleted</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add/Remove Notes</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

            </v-expansion-panels>
        </v-row>

        <v-row
            v-if="projectSelected != null && projectSelected != undefined"
        >
            <v-col
                style="padding: 0px !important;"
                cols="12"
            >
                <v-card-text>

                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                        
                        <v-row>
                            <v-col 
                                style="text-align: left;"
                                cols="6"
                                lg="10"
                                md="6"
                                sm="6"
                            >
                                <label>Total Upgrade Amount</label>
                            </v-col>
                            <v-col 
                                style="text-align: right;"
                                cols="6"
                                lg="2"
                                md="6"
                                sm="6"
                            >
                                <label style="color: var(--color__main); font-size: 30px !important;">{{ getTotalUpgradeAmount }}</label>
                            </v-col>
                        </v-row>                        
                    </v-card-title>
                </v-card-text>
            </v-col>
        </v-row>

        <v-row
            v-if="canEdit == true"
        >
            <v-col
                cols="12"
                style="padding: 0px !important;"
            >
                <v-card-text>

                    <v-card
                        class="mx-auto"
                    >
                        <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                            Files
                        </v-card-title>
                        <v-card-text style="margin-top: 20px;">
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <canvas id="canvas" hidden></canvas>
                                    <label>Select files</label>
                                    <v-file-input
                                        v-model="colorSelectionFilesPhotos" 
                                        accept="image/*"
                                        small-chips
                                        multiple
                                        outlined
                                        placeholder="Click here to add"
                                        prepend-inner-icon="mdi-paperclip"
                                        prepend-icon=""
                                        @change="resizePreviewImage(colorSelectionFilesPhotos, colorSelectionPictures)"
                                    ></v-file-input>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    v-for="(itemFile, index) in colorSelectionPictures.filter(pht => pht.deleted == false)"
                                    :key="index"
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-card
                                        v-if="itemFile.type.toString().toLowerCase().includes('pdf')"
                                        max-width="200"
                                        class="justify-center"
                                        @click="downloadFile(URL_ATTACHMENT, itemFile.id, itemFile.type, itemFile.fileName)"
                                    >
                                        <v-card-text
                                            style="height: 124px; text-align: center;"
                                        >
                                            <v-icon x-large>mdi mdi-file-pdf-box</v-icon>
                                            <br />
                                            PDF File
                                        </v-card-text>
                                        <span class="imageFileName">{{ itemFile.fileName }}</span>
                                        <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click.stop="" @click="confirmPhotoDelete(itemFile)">mdi mdi-delete</v-icon>
                                    </v-card>

                                    <v-card 
                                        v-else
                                        max-width="200"
                                        @click="downloadFile(URL_ATTACHMENT, itemFile.id, itemFile.type, itemFile.fileName)"
                                    >
                                        <v-img
                                            :src="itemFile.src"
                                            :lazy-src="itemFile.src"
                                            aspect-ratio="1"
                                            max-height="200"
                                            max-width="200"
                                        >
                                            <template v-slot:placeholder>
                                            <v-row
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                            >
                                                <v-progress-circular
                                                    indeterminate
                                                    color="grey lighten-5"
                                                ></v-progress-circular>
                                            </v-row>
                                            </template>
                                        </v-img>
                                        <span class="imageFileName">{{ itemFile.fileName }}</span>
                                        <v-icon
                                            class="deletePhotoIcon" 
                                            small 
                                            right 
                                            color="var(--color__red)" 
                                            @click="confirmPhotoDelete(itemFile)"
                                        >
                                            mdi mdi-delete
                                        </v-icon>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>

                    </v-card>
                    
                </v-card-text>
            </v-col>
        </v-row>

        <v-row
            v-if="projectSelected != null && projectSelected != undefined"
        >

            <v-col
                style="padding: 0px !important;"
                cols="12"
            >
                <v-card-text>

                    <v-card
                        class="mx-auto"
                    >
                        <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                            <v-col cols="4">
                                Color Selection
                            </v-col>
                            <v-col cols="8" style="text-align: right;">
                                <v-chip
                                    style="font-size: 12px !important;"
                                    :color="statusColorColorSelection"
                                    outlined
                                    dark
                                >
                                    {{ statusDescriptionColorSelection }}
                                </v-chip>

                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-chip
                                            style="font-size: 12px !important; margin-left: 10px;"
                                            v-on="on"
                                            color="primary"
                                            outlined
                                            dark
                                            link
                                            @click="showDialogDocusign(TYPE_DOCUMENT.COLOR_SELECTION)"
                                        >
                                            Docusign
                                        </v-chip>
                                    </template>
                                    <span>Click to view docusign options</span>
                                </v-tooltip>
                            </v-col>
                        </v-card-title>
                        <v-card-text style="margin-top: 20px;">
                            <v-row>
                                <v-col
                                    cols="12"
                                    lg="5"
                                    md="12"
                                    sm="12"
                                >
                                    <span style="font-size: 13px;"><strong>Step 1:</strong> First you must download the color selection and sign, after you must to send it to the customer for sign</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                style="width: 100% !important; margin-top: 2rem; margin-left: 0px !important;"
                                                class="mx-2"
                                                v-on="on"
                                                @click="downloadColorSelection()"
                                            >
                                                <v-icon left>mdi-file-download-outline</v-icon>
                                                Download PDF Color Selection
                                            </v-btn>
                                        </template>
                                        <span>Download PDF</span>
                                    </v-tooltip>

                                </v-col>
                                <v-col
                                    style="text-align: center;"
                                    cols="12"
                                    lg="2"
                                    md="12"
                                    sm="12"
                                >
                                
                                    <v-divider vertical></v-divider>

                                </v-col>

                                <v-col
                                    cols="12"
                                    lg="5"
                                    md="12"
                                    sm="12"
                                    v-if="canEdit == true"
                                >
                                    <span style="font-size: 13px;"><strong>Step 2:</strong> Upload the color selection signed to complete this process</span>
                                    
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                style="width: 100% !important; margin-top: 2rem; margin-left: 0px !important;"
                                                class="mx-2"
                                                v-on="on"
                                                @click="uploadSignedColorSelection()"
                                            >
                                                <v-icon left>mdi-file-upload-outline</v-icon>
                                                Upload Color Selection
                                            </v-btn>
                                        </template>
                                        <span>Upload Signed Document</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                            
                        </v-card-text>

                    </v-card>
                </v-card-text>
            </v-col>
        </v-row>

        <v-dialog
            v-model="showDialogItemNote"
            transition="dialog-top-transition"
            persistent
            width="800"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
        
            <v-card>
                <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                    {{ itemDescription }}
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Notes</label>
                            
                            <v-textarea
                                v-model="note"
                                single-line
                                rows="3"
                                row-height="30"
                                outlined
                                :disabled="!canEdit"
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <div style="width: 100%; text-align: center;">
                        <v-btn
                            class="mx-2 buttonCancelDialog v-btn-large"
                            style=""
                            outlined
                            @click="showDialogItemNote = false"
                        >
                            Cancel
                        </v-btn>

                        <v-btn
                            class="mx-2 v-btn-large"
                            outlined
                            @click="saveItemNote()"
                        >
                            Save
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <ActionButtons
            v-if="canEdit == true"
            :request="colorSelectionRequest"
            :loading="loading"
            :showButtons="{
                cancel: true,
                save: true
            }"
            @cancel="cancel"
            @save="validate"
        />

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

        <UploadFile 
            v-on:methodConfirmAfterUpload="dialogUploadFile.methodAfterUpload"
            :showUploadFile.sync="dialogUploadFile.show"
            :idKey="dialogUploadFile.idKey"
            :reload="dialogUploadFile.reload"
            :acceptType="dialogUploadFile.acceptType"
            :typeAttachment="dialogUploadFile.typeAttachment"
            :typeDocument="dialogUploadFile.typeDocument"
            :multipleFiles="dialogUploadFile.multipleFiles"
            :showMsgConfirmation="dialogUploadFile.showMsgConfirmation"
            :msgConfirmation="dialogUploadFile.msgConfirmation"
        />

        <div 
            v-if="projectSelected == null || projectSelected == undefined"
            class="text-center"
        >            
            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn 
                                class="mx-2 v-btnCustom"
                                v-on="on"
                                style="background-color: var(--color__silver) !important; color: #ffffff !important"
                                @click="cancel()"
                            >
                                Back
                            </v-btn>
                        </template>
                        <span>Back</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </div>

        <DialogDocusign 
            v-on:methodConfirmToCall="dialogDocusign.methodConfirm"
            :showDialogDocusign.sync="dialogDocusign.show"
            :idAux="dialogDocusign.idAux"
            :id="dialogDocusign.id"
            :typeDocument="dialogDocusign.typeDocument"
            :customer="dialogDocusign.customer"
        />

    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import { required } from "@/utilities/Rules";
    import ActionDialog from "@/components/Layout/ActionDialog";
    import UploadFile from "@/components/File/UploadFile";
    import ActionButtons from "@/components/Layout/ActionButtons";
    import { formaterDecimalBR } from '@/utilities/Utils';
    import JobInformation from "@/components/Services/JobInformation";
    import { PROJECT_STATUS } from "@/utilities/Enums";
    import DialogDocusign from "@/components/Services/DialogDocusign";
    import { 
        TYPE_DOCUMENT, 
        TYPE_ATTACHMENT,
        URL_ATTACHMENT, 
        URL_ATTACHMENT_COLOR_SELECTION
    } from "@/utilities/Enums";

    export default ({

        components: {
            ActionDialog,
            UploadFile,
            ActionButtons,
            JobInformation,
            DialogDocusign
        },

        mixins: [Helpers],

        data: () => ({

            URL_ATTACHMENT,
            URL_ATTACHMENT_COLOR_SELECTION,

            loading: false,

			serviceRequest: {
                id: 0
            },

            panel: [],

            colorSelectionRequest: {
                listCoping: [],
                listSpa: [],
                listPoolWaterline: [],
                listPoolCapTile: [],
                listSpaWaterline: [],
                listSpaCapTile: [],
                listSpaExteriorWall: [],
                listSpaSpillway: [],
                listTroughTile: [],
                listFrontRaisedWall: [],
                listSidesRaisedWall: [],
                listBackRaisedWall: [],
                listFrontPedestal: [],
                listSidesPedestal: [],
                listBackPedestal: [],
                listTopPedestal: [],
                listTileInserts: [],
                listMosaics: [],
                listScuppers: [],
                listTableTop: [],
                listTableSides: [],
                listTableBase: [],
                listBarStoolsTop: [],
                listBarStoolsSides: [],
                listBarStoolsBase: [],
                listHandholdSelection: [],
                listWaterfallColor: [],
                listFourPieceDeck: [],
                listDeck6x12: [],
                listDeck12x24: [],
                listDeck16x24: [],
                listRetainingWallBackMaterial: [],
                listInteriorPoolFinish: [],
                listColumnsSides: [],
                listColumnsTop: [],
                listFirePitTopMaterial: [],
                listFirePitSidesMaterial: [],
                listFirePitBackMaterial: [],
                listFirePitFrontMaterial: [],
                listBBQTopMaterial: [],
                listBBQSidesMaterial: [],
                listBBQBackMaterial: [],
                listBBQFrontMaterial: [],
                listBBQBlackSplashMaterial: [],
                listBowlsColor: [],
                listBabyFence: [],
                listPerimeterFence: [],
                listPergola: [],
                listFittingColor: [],
            },

            listProjects: [],
            projectSelected: null,
            refreshJob: false,
            listCSMaterialType: [],
            listCSProducts: [],
            
            listPattern: [
                { id: 1, description: 'Soldier', image: 'copingpatternsoldier.png' },
                { id: 2, description: 'End to End', image: 'copingpatternendtoend.png' },
            ],

            listFourPiecePattern: [
                { id: 1, description: 'French', image: 'piecepatternfrench.png' },
            ],
            
            list6x12Pattern: [
                { id: 1, description: '90 Herringbone', image: '90herringbone.png' },
                { id: 2, description: 'Parquet', image: 'parquet.png' },
                { id: 3, description: 'Runner Bond Vertical', image: 'runnerbondvertical.png' },
                { id: 4, description: 'Runner Bond Horizontal', image: 'runnerbondhorizontal.png' },
            ],
            
            list12x24And16x24Pattern: [
                { id: 1, description: '90 Herringbone', image: '90herringbone.png' },
                { id: 3, description: 'Runner Bond Vertical', image: 'runnerbondvertical.png' },
                { id: 4, description: 'Runner Bond Horizontal', image: 'runnerbondhorizontal.png' },
            ],

            idServiceProjectColorSelectionItem: 0,
            itemDescription: "",
            note: "",
            itemNote: "",
            showDialogItemNote: false,

            moneyFormat: {
                prefix: '',
                suffix: '',
                thousands: ',',
                decimal: '.',
                precision: 2,
            },

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            dialogUploadFile: {
                show: false,
                idKey: 0,
                reload: 0,
                acceptType: ".pdf",
                typeAttachment: 0,
                typeDocument: 0,
                multipleFiles: false,
                showMsgConfirmation: true,
                msgConfirmation: "",
                methodAfterUpload: ( () => {} ),
            },

            dialogDocusign: {
                show: false,
                idAux: 0,
                id: 0,
                typeDocument: null,
                customer: null,
                methodConfirm: ( () => {} )
            },

            statusColorColorSelection: "var(--color__main)",
            statusDescriptionColorSelection: "Completed",

            colorSelectionFilesPhotos: [],
            colorSelectionPictures: [],

            PROJECT_STATUS,

            showButtonEyesSections: false,
            showHideButtonSections: false,

            showSections: {
                poolCoping: true,
                spaCoping: true,
                poolWaterline: true,
                poolCapTile: true,
                spaWaterline: true,
                spaCapTile: true,
                spaExteriorWall: true,
                spaSpillWay: true,
                troughTile: true,
                frontRaisedWall: true,
                sidesRaisedWall: true,
                backRaisedWall: true,
                frontPedestal: true,
                sidesPedestal: true,
                backPedestal: true,
                topPedestal: true,
                tileInserts: true,
                mosaics: true,
                scuppers: true,
                tableTop: true,
                tableSides: true,
                tableBase: true,
                barStoolTop: true,
                barStoolSides: true,
                barStoolBase: true,
                handHold: true,
                waterFallColor: true,
                fourPieceDeck: true,
                deck6x12: true,
                deck12x24: true,
                deck16x24: true,
                retainingWallBackMaterial: true,
                interiorPoolFinish: true,
                columnSides: true,
                columnTop: true,
                firePitTopMaterial: true,
                firePitSidesMaterial: true,
                firePitBackMaterial: true,
                firePitFrontMaterial: true,
                bbqTopMaterial: true,
                bbqSidesMaterial: true,
                bbqBackMaterial: true,
                bbqFrontMaterial: true,
                bbqBlackSplashMaterial: true,
                bowlsColor: true,
                babyFence: true,
                perimiterFence: true,
                pergola: true,
                fittingColor: true,
            },
            
            validations: {
                required: required,
            },
            
            TYPE_DOCUMENT: TYPE_DOCUMENT
        }),

        props: {
            id: {
                default: 0
            },

            params: {
                default: ""
            }
        },

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            getTotalUpgradeAmount() {

                let totalAmount = 0;

                if (this.colorSelectionRequest != null && this.colorSelectionRequest != undefined) {
                    this.colorSelectionRequest.listCoping.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listSpa.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listPoolWaterline.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listPoolCapTile.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listSpaWaterline.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listSpaCapTile.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listSpaExteriorWall.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listSpaSpillway.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listTroughTile.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listFrontRaisedWall.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listSidesRaisedWall.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listBackRaisedWall.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listFrontPedestal.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listSidesPedestal.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listBackPedestal.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listTopPedestal.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listTileInserts.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listMosaics.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listScuppers.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listTableTop.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listTableSides.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listTableBase.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listBarStoolsTop.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listBarStoolsSides.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listBarStoolsBase.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listHandholdSelection.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listFourPieceDeck.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listDeck6x12.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listDeck12x24.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listDeck16x24.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listInteriorPoolFinish.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listColumnsSides.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listColumnsTop.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listFirePitTopMaterial.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listFirePitSidesMaterial.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listFirePitBackMaterial.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listFirePitFrontMaterial.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listBBQTopMaterial.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listBBQSidesMaterial.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listBBQBackMaterial.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listBBQFrontMaterial.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listBBQBlackSplashMaterial.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                    this.colorSelectionRequest.listBowlsColor.forEach(item => { totalAmount += parseFloat(item.upgrade ?? 0); });
                }

                return `$ ${formaterDecimalBR(totalAmount.toFixed(2))}`;
            },

            isPool() {
                return this.projectSelected != null && this.projectSelected != undefined && this.projectSelected.pool == 1;
            },

            isBBQ() {
                return this.projectSelected != null && this.projectSelected != undefined && this.projectSelected.bbq == 1;
            },

            isPergola() {
                return this.projectSelected != null && this.projectSelected != undefined && this.projectSelected.pergola == 1;
            },

            canEdit() {

                if (this.projectSelected != null && 
                    this.projectSelected != undefined && 
                    this.projectSelected.status < this.PROJECT_STATUS.CONSTRUCTION_PREDIG_PRESITE.id ||
                    this.userLoggedGetters.profile.editColorSelectionAfterPermitting == 1
                ) {
                    return true;
                }
                else {
                    return false;
                }
            },
        },

        methods: {

            setColor(list) {
                
                if (list != null && list != undefined) {
                    const anyItem = list.filter(item => item.item && item.item != '' || (item.productSelected != null && item.productSelected != undefined && item.productSelected.id != '') ) [0];

                    if (list != null && 
                        list != undefined && 
                        list.length > 0 &&
                        anyItem != null && 
                        anyItem != undefined
                    ) {
                        return 'primary';
                    }
                }
                return '';
            },

            canEditPrice(item) {
                const canEditPrice = 
                        item.productSelected != null &&
                        item.productSelected != undefined && 
                        item.productSelected.id == 2;

                const inApprove = 
                        this.userLoggedGetters.profile.systemAdmin == 1 &&
                        item.needsApproval == true && 
                        item.approved == false;

                return canEditPrice || inApprove;
            },
            
            async cancel() {
                history.go(-1);
            },

            async getLists() {
                this.listCSMaterialType = await this.$store.dispatch("colorSelectionMaterialTypeModule/List");
                this.listCSProducts = await this.$store.dispatch("colorSelectionProductModule/List");
            },
            
            async getRegister() {

                if (this.id !== 0) {

                    const response = await this.$store.dispatch("serviceModule/GetById", this.id);

                    this.serviceRequest = response.result;

                    this.panel = [...Array(43).keys()];
                }
            },

            getIDProject() {

                let idServiceProject = 0;

                if (this.projectSelected != null && this.projectSelected != null) {
                    idServiceProject = this.projectSelected.id;
                }

                return idServiceProject;
            },

            async checkAndAddNewRegistersToList() {

                if (this.colorSelectionRequest.listCoping == null || this.colorSelectionRequest.listCoping.length <= 0) {
                    this.colorSelectionRequest.listCoping = [];
                    this.addNewItem(null, this.colorSelectionRequest.listCoping);
                }

                if (this.colorSelectionRequest.listSpa == null || this.colorSelectionRequest.listSpa.length <= 0) {
                    this.colorSelectionRequest.listSpa = [];
                    this.addNewItem(null, this.colorSelectionRequest.listSpa);
                }

                if (this.colorSelectionRequest.listPoolWaterline == null || this.colorSelectionRequest.listPoolWaterline.length <= 0) {
                    this.colorSelectionRequest.listPoolWaterline = [];
                    this.addNewItem(null, this.colorSelectionRequest.listPoolWaterline);
                }

                if (this.colorSelectionRequest.listPoolCapTile == null || this.colorSelectionRequest.listPoolCapTile.length <= 0) {
                    this.colorSelectionRequest.listPoolCapTile = [];
                    this.addNewItem(null, this.colorSelectionRequest.listPoolCapTile);
                }

                if (this.colorSelectionRequest.listSpaWaterline == null || this.colorSelectionRequest.listSpaWaterline.length <= 0) {
                    this.colorSelectionRequest.listSpaWaterline = [];
                    this.addNewItem(null, this.colorSelectionRequest.listSpaWaterline);
                }

                if (this.colorSelectionRequest.listSpaCapTile == null || this.colorSelectionRequest.listSpaCapTile.length <= 0) {
                    this.colorSelectionRequest.listSpaCapTile = [];
                    this.addNewItem(null, this.colorSelectionRequest.listSpaCapTile);
                }

                if (this.colorSelectionRequest.listSpaExteriorWall == null || this.colorSelectionRequest.listSpaExteriorWall.length <= 0) {
                    this.colorSelectionRequest.listSpaExteriorWall = [];
                    this.addNewItem(null, this.colorSelectionRequest.listSpaExteriorWall);
                }

                if (this.colorSelectionRequest.listSpaSpillway == null || this.colorSelectionRequest.listSpaSpillway.length <= 0) {
                    this.colorSelectionRequest.listSpaSpillway = [];
                    this.addNewItem(null, this.colorSelectionRequest.listSpaSpillway);
                }

                if (this.colorSelectionRequest.listTroughTile == null || this.colorSelectionRequest.listTroughTile.length <= 0) {
                    this.colorSelectionRequest.listTroughTile = [];
                    this.addNewItem(null, this.colorSelectionRequest.listTroughTile);
                }

                if (this.colorSelectionRequest.listFrontRaisedWall == null || this.colorSelectionRequest.listFrontRaisedWall.length <= 0) {
                    this.colorSelectionRequest.listFrontRaisedWall = [];
                    this.addNewItem(null, this.colorSelectionRequest.listFrontRaisedWall);
                }

                if (this.colorSelectionRequest.listSidesRaisedWall == null || this.colorSelectionRequest.listSidesRaisedWall.length <= 0) {
                    this.colorSelectionRequest.listSidesRaisedWall = [];
                    this.addNewItem(null, this.colorSelectionRequest.listSidesRaisedWall);
                }

                if (this.colorSelectionRequest.listBackRaisedWall == null || this.colorSelectionRequest.listBackRaisedWall.length <= 0) {
                    this.colorSelectionRequest.listBackRaisedWall = [];
                    this.addNewItem(null, this.colorSelectionRequest.listBackRaisedWall);
                }

                if (this.colorSelectionRequest.listFrontPedestal == null || this.colorSelectionRequest.listFrontPedestal.length <= 0) {
                    this.colorSelectionRequest.listFrontPedestal = [];
                    this.addNewItem(null, this.colorSelectionRequest.listFrontPedestal);
                }

                if (this.colorSelectionRequest.listSidesPedestal == null || this.colorSelectionRequest.listSidesPedestal.length <= 0) {
                    this.colorSelectionRequest.listSidesPedestal = [];
                    this.addNewItem(null, this.colorSelectionRequest.listSidesPedestal);
                }

                if (this.colorSelectionRequest.listBackPedestal == null || this.colorSelectionRequest.listBackPedestal.length <= 0) {
                    this.colorSelectionRequest.listBackPedestal = [];
                    this.addNewItem(null, this.colorSelectionRequest.listBackPedestal);
                }

                if (this.colorSelectionRequest.listTopPedestal == null || this.colorSelectionRequest.listTopPedestal.length <= 0) {
                    this.colorSelectionRequest.listTopPedestal = [];
                    this.addNewItem(null, this.colorSelectionRequest.listTopPedestal);
                }

                if (this.colorSelectionRequest.listTileInserts == null || this.colorSelectionRequest.listTileInserts.length <= 0) {
                    this.colorSelectionRequest.listTileInserts = [];
                    this.addNewItem(null, this.colorSelectionRequest.listTileInserts);
                }

                if (this.colorSelectionRequest.listMosaics == null || this.colorSelectionRequest.listMosaics.length <= 0) {
                    this.colorSelectionRequest.listMosaics = [];
                    this.addNewItem(null, this.colorSelectionRequest.listMosaics);
                }

                if (this.colorSelectionRequest.listScuppers == null || this.colorSelectionRequest.listScuppers.length <= 0) {
                    this.colorSelectionRequest.listScuppers = [];
                    this.addNewItem(null, this.colorSelectionRequest.listScuppers);
                }

                if (this.colorSelectionRequest.listTableTop == null || this.colorSelectionRequest.listTableTop.length <= 0) {
                    this.colorSelectionRequest.listTableTop = [];
                    this.addNewItem(null, this.colorSelectionRequest.listTableTop);
                }

                if (this.colorSelectionRequest.listTableSides == null || this.colorSelectionRequest.listTableSides.length <= 0) {
                    this.colorSelectionRequest.listTableSides = [];
                    this.addNewItem(null, this.colorSelectionRequest.listTableSides);
                }

                if (this.colorSelectionRequest.listTableBase == null || this.colorSelectionRequest.listTableBase.length <= 0) {
                    this.colorSelectionRequest.listTableBase = [];
                    this.addNewItem(null, this.colorSelectionRequest.listTableBase);
                }

                if (this.colorSelectionRequest.listBarStoolsTop == null || this.colorSelectionRequest.listBarStoolsTop.length <= 0) {
                    this.colorSelectionRequest.listBarStoolsTop = [];
                    this.addNewItem(null, this.colorSelectionRequest.listBarStoolsTop);
                }

                if (this.colorSelectionRequest.listBarStoolsSides == null || this.colorSelectionRequest.listBarStoolsSides.length <= 0) {
                    this.colorSelectionRequest.listBarStoolsSides = [];
                    this.addNewItem(null, this.colorSelectionRequest.listBarStoolsSides);
                }

                if (this.colorSelectionRequest.listBarStoolsBase == null || this.colorSelectionRequest.listBarStoolsBase.length <= 0) {
                    this.colorSelectionRequest.listBarStoolsBase = [];
                    this.addNewItem(null, this.colorSelectionRequest.listBarStoolsBase);
                }

                if (this.colorSelectionRequest.listHandholdSelection == null || this.colorSelectionRequest.listHandholdSelection.length <= 0) {
                    this.colorSelectionRequest.listHandholdSelection = [];
                    this.addNewItem(null, this.colorSelectionRequest.listHandholdSelection);
                }

                if (this.colorSelectionRequest.listWaterfallColor == null || this.colorSelectionRequest.listWaterfallColor.length <= 0) {
                    this.colorSelectionRequest.listWaterfallColor = [];
                    this.addNewItem(null, this.colorSelectionRequest.listWaterfallColor);
                }

                if (this.colorSelectionRequest.listFourPieceDeck == null || this.colorSelectionRequest.listFourPieceDeck.length <= 0) {
                    this.colorSelectionRequest.listFourPieceDeck = [];
                    this.addNewItem(null, this.colorSelectionRequest.listFourPieceDeck);
                }

                if (this.colorSelectionRequest.listDeck6x12 == null || this.colorSelectionRequest.listDeck6x12.length <= 0) {
                    this.colorSelectionRequest.listDeck6x12 = [];
                    this.addNewItem(null, this.colorSelectionRequest.listDeck6x12);
                }

                if (this.colorSelectionRequest.listDeck12x24 == null || this.colorSelectionRequest.listDeck12x24.length <= 0) {
                    this.colorSelectionRequest.listDeck12x24 = [];
                    this.addNewItem(null, this.colorSelectionRequest.listDeck12x24);
                }

                if (this.colorSelectionRequest.listDeck16x24 == null || this.colorSelectionRequest.listDeck16x24.length <= 0) {
                    this.colorSelectionRequest.listDeck16x24 = [];
                    this.addNewItem(null, this.colorSelectionRequest.listDeck16x24);
                }

                if (this.colorSelectionRequest.listRetainingWallBackMaterial == null || this.colorSelectionRequest.listRetainingWallBackMaterial.length <= 0) {
                    this.colorSelectionRequest.listRetainingWallBackMaterial = [];
                    this.addNewItem(null, this.colorSelectionRequest.listRetainingWallBackMaterial);
                }

                if (this.colorSelectionRequest.listInteriorPoolFinish == null || this.colorSelectionRequest.listInteriorPoolFinish.length <= 0) {
                    this.colorSelectionRequest.listInteriorPoolFinish = [];
                    this.addNewItem(null, this.colorSelectionRequest.listInteriorPoolFinish);
                }

                if (this.colorSelectionRequest.listColumnsSides == null || this.colorSelectionRequest.listColumnsSides.length <= 0) {
                    this.colorSelectionRequest.listColumnsSides = [];
                    this.addNewItem(null, this.colorSelectionRequest.listColumnsSides);
                }

                if (this.colorSelectionRequest.listColumnsTop == null || this.colorSelectionRequest.listColumnsTop.length <= 0) {
                    this.colorSelectionRequest.listColumnsTop = [];
                    this.addNewItem(null, this.colorSelectionRequest.listColumnsTop);
                }

                if (this.colorSelectionRequest.listFirePitTopMaterial == null || this.colorSelectionRequest.listFirePitTopMaterial.length <= 0) {
                    this.colorSelectionRequest.listFirePitTopMaterial = [];
                    this.addNewItem(null, this.colorSelectionRequest.listFirePitTopMaterial);
                }

                if (this.colorSelectionRequest.listFirePitSidesMaterial == null || this.colorSelectionRequest.listFirePitSidesMaterial.length <= 0) {
                    this.colorSelectionRequest.listFirePitSidesMaterial = [];
                    this.addNewItem(null, this.colorSelectionRequest.listFirePitSidesMaterial);
                }

                if (this.colorSelectionRequest.listFirePitBackMaterial == null || this.colorSelectionRequest.listFirePitBackMaterial.length <= 0) {
                    this.colorSelectionRequest.listFirePitBackMaterial = [];
                    this.addNewItem(null, this.colorSelectionRequest.listFirePitBackMaterial);
                }

                if (this.colorSelectionRequest.listFirePitFrontMaterial == null || this.colorSelectionRequest.listFirePitFrontMaterial.length <= 0) {
                    this.colorSelectionRequest.listFirePitFrontMaterial = [];
                    this.addNewItem(null, this.colorSelectionRequest.listFirePitFrontMaterial);
                }

                if (this.colorSelectionRequest.listBBQTopMaterial == null || this.colorSelectionRequest.listBBQTopMaterial.length <= 0) {
                    this.colorSelectionRequest.listBBQTopMaterial = [];
                    this.addNewItem(null, this.colorSelectionRequest.listBBQTopMaterial);
                }

                if (this.colorSelectionRequest.listBBQSidesMaterial == null || this.colorSelectionRequest.listBBQSidesMaterial.length <= 0) {
                    this.colorSelectionRequest.listBBQSidesMaterial = [];
                    this.addNewItem(null, this.colorSelectionRequest.listBBQSidesMaterial);
                }

                if (this.colorSelectionRequest.listBBQBackMaterial == null || this.colorSelectionRequest.listBBQBackMaterial.length <= 0) {
                    this.colorSelectionRequest.listBBQBackMaterial = [];
                    this.addNewItem(null, this.colorSelectionRequest.listBBQBackMaterial);
                }

                if (this.colorSelectionRequest.listBBQFrontMaterial == null || this.colorSelectionRequest.listBBQFrontMaterial.length <= 0) {
                    this.colorSelectionRequest.listBBQFrontMaterial = [];
                    this.addNewItem(null, this.colorSelectionRequest.listBBQFrontMaterial);
                }

                if (this.colorSelectionRequest.listBBQBlackSplashMaterial == null || this.colorSelectionRequest.listBBQBlackSplashMaterial.length <= 0) {
                    this.colorSelectionRequest.listBBQBlackSplashMaterial = [];
                    this.addNewItem(null, this.colorSelectionRequest.listBBQBlackSplashMaterial);
                }

                if (this.colorSelectionRequest.listBowlsColor == null || this.colorSelectionRequest.listBowlsColor.length <= 0) {
                    this.colorSelectionRequest.listBowlsColor = [];
                    this.addNewItem(null, this.colorSelectionRequest.listBowlsColor);
                }

                if (this.colorSelectionRequest.listBabyFence == null || this.colorSelectionRequest.listBabyFence.length <= 0) {
                    this.colorSelectionRequest.listBabyFence = [];
                    this.addNewItem(null, this.colorSelectionRequest.listBabyFence);
                }
                
                if (this.colorSelectionRequest.listPerimeterFence == null || this.colorSelectionRequest.listPerimeterFence.length <= 0) {
                    this.colorSelectionRequest.listPerimeterFence = [];
                    this.addNewItem(null, this.colorSelectionRequest.listPerimeterFence);
                }

                if (this.colorSelectionRequest.listPergola == null || this.colorSelectionRequest.listPergola.length <= 0) {
                    this.colorSelectionRequest.listPergola = [];
                    this.addNewItem(null, this.colorSelectionRequest.listPergola);
                }

                if (this.colorSelectionRequest.listFittingColor == null || this.colorSelectionRequest.listFittingColor.length <= 0) {
                    this.colorSelectionRequest.listFittingColor = [];
                    this.addNewItem(null, this.colorSelectionRequest.listFittingColor);
                }
            },

            async clearColorSelection() {

                this.colorSelectionRequest = {
                    id: 0,
                    idServiceProject: 0,
                    listCoping: [],
                    listSpa: [],
                    listPoolWaterline: [],
                    listPoolCapTile: [],
                    listSpaWaterline: [],
                    listSpaCapTile: [],
                    listSpaExteriorWall: [],
                    listSpaSpillway: [],
                    listTroughTile: [],
                    listFrontRaisedWall: [],
                    listSidesRaisedWall: [],
                    listBackRaisedWall: [],
                    listFrontPedestal: [],
                    listSidesPedestal: [],
                    listBackPedestal: [],
                    listTopPedestal: [],
                    listTileInserts: [],
                    listMosaics: [],
                    listScuppers: [],
                    listTableTop: [],
                    listTableSides: [],
                    listTableBase: [],
                    listBarStoolsTop: [],
                    listBarStoolsSides: [],
                    listBarStoolsBase: [],
                    listHandholdSelection: [],
                    listWaterfallColor: [],
                    listFourPieceDeck: [],
                    listDeck6x12: [],
                    listDeck12x24: [],
                    listDeck16x24: [],
                    listRetainingWallBackMaterial: [],
                    listInteriorPoolFinish: [],
                    listColumnsSides: [],
                    listColumnsTop: [],
                    listFirePitTopMaterial: [],
                    listFirePitSidesMaterial: [],
                    listFirePitBackMaterial: [],
                    listFirePitFrontMaterial: [],
                    listBBQTopMaterial: [],
                    listBBQSidesMaterial: [],
                    listBBQBackMaterial: [],
                    listBBQFrontMaterial: [],
                    listBBQBlackSplashMaterial: [],
                    listBowlsColor: [],
                    listBabyFence: [],
                    listPerimeterFence: [],
                    listPergola: [],
                    listFittingColor: [],
                };
            },

            async getColorSelection() {
                
                this.clearColorSelection();

                const idServiceProject = this.getIDProject();

                const responseColorSelection = await this.$store.dispatch("serviceModule/GetColorSelectionById", idServiceProject );

                if (responseColorSelection.success) {
                    const result = responseColorSelection.result;

                    if (result != null && result != undefined && result.signed == 0) {

                        this.colorSelectionRequest = {
                            id: result.id,
                            idServiceProject: result.idServiceProject,
                            listCoping: JSON.parse(result.coping),
                            listSpa: JSON.parse(result.spa),
                            listPoolWaterline: JSON.parse(result.poolWaterline),
                            listPoolCapTile: JSON.parse(result.poolCapTile),
                            listSpaWaterline: JSON.parse(result.spaWaterline),
                            listSpaCapTile: JSON.parse(result.spaCapTile),
                            listSpaExteriorWall: JSON.parse(result.spaExteriorWall),
                            listSpaSpillway: JSON.parse(result.spaSpillway),
                            listTroughTile: JSON.parse(result.troughTile),
                            listFrontRaisedWall: JSON.parse(result.frontRaisedWall),
                            listSidesRaisedWall: JSON.parse(result.sidesRaisedWall),
                            listBackRaisedWall: JSON.parse(result.backRaisedWall),
                            listFrontPedestal: JSON.parse(result.frontPedestal),
                            listSidesPedestal: JSON.parse(result.sidesPedestal),
                            listBackPedestal: JSON.parse(result.backPedestal),
                            listTopPedestal: JSON.parse(result.topPedestal),
                            listTileInserts: JSON.parse(result.tileInserts),
                            listMosaics: JSON.parse(result.mosaics),
                            listScuppers: JSON.parse(result.scuppers),
                            listTableTop: JSON.parse(result.tableTop),
                            listTableSides: JSON.parse(result.tableSides),
                            listTableBase: JSON.parse(result.tableBase),
                            listBarStoolsTop: JSON.parse(result.barStoolsTop),
                            listBarStoolsSides: JSON.parse(result.barStoolsSides),
                            listBarStoolsBase: JSON.parse(result.barStoolsBase),
                            listHandholdSelection: JSON.parse(result.handholdSelection),
                            listWaterfallColor: JSON.parse(result.waterfallColor),
                            listFourPieceDeck: JSON.parse(result.fourPieceDeck),
                            listDeck6x12: JSON.parse(result.deck6x12),
                            listDeck12x24: JSON.parse(result.deck12x24),
                            listDeck16x24: JSON.parse(result.deck16x24),
                            listRetainingWallBackMaterial: JSON.parse(result.retainingWallBackMaterial),
                            listInteriorPoolFinish: JSON.parse(result.interiorPoolFinish),
                            listColumnsSides: JSON.parse(result.columnsSides),
                            listColumnsTop: JSON.parse(result.columnsTop),
                            listFirePitTopMaterial: JSON.parse(result.firePitTopMaterial),
                            listFirePitSidesMaterial: JSON.parse(result.firePitSidesMaterial),
                            listFirePitBackMaterial: JSON.parse(result.firePitBackMaterial),
                            listFirePitFrontMaterial: JSON.parse(result.firePitFrontMaterial),
                            listBBQTopMaterial: JSON.parse(result.bbqTopMaterial),
                            listBBQSidesMaterial: JSON.parse(result.bbqSidesMaterial),
                            listBBQBackMaterial: JSON.parse(result.bbqBackMaterial),
                            listBBQFrontMaterial: JSON.parse(result.bbqFrontMaterial),
                            listBBQBlackSplashMaterial: JSON.parse(result.bbqBlackSplashMaterial),
                            listBowlsColor: JSON.parse(result.bowlsColor),
                            listBabyFence: JSON.parse(result.babyFence),
                            listPerimeterFence: JSON.parse(result.perimeterFence),
                            listPergola: JSON.parse(result.pergola),
                            listFittingColor: JSON.parse(result.fittingColor),
                        };
                    }
                    
                    this.colorSelectionFilesPhotos = [];
                    this.colorSelectionPictures = [];
                    
                    const statusCompletedColor = "var(--color__main)";
                    const statusCompletedDescription = "Completed";
                    const statusPendingColor = "var(--color__alert)";
                    const statusPendingDescription = "Pending";
                    
                    const listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.COLOR_SELECTION.value, idKey: this.colorSelectionRequest.id });

                    const contractUploaded = listAttachments.filter(item => item.typeDocument == TYPE_DOCUMENT.COLOR_SELECTION.value)[0];

                    if (contractUploaded == null || contractUploaded == undefined) {
                        this.statusColorColorSelection = statusPendingColor;
                        this.statusDescriptionColorSelection = statusPendingDescription;
                    }
                    else {
                        this.statusColorColorSelection = statusCompletedColor;
                        this.statusDescriptionColorSelection = statusCompletedDescription;
                    }

                    const colorSelectionFilesPhotos = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.COLOR_SELECTION_PICTURES.value);
                    if (colorSelectionFilesPhotos != null && colorSelectionFilesPhotos != undefined) {

                        colorSelectionFilesPhotos.forEach(itemPicture => {

                            this.colorSelectionPictures.push({
                                id: itemPicture.id,
                                file: new File([], itemPicture.fileName),
                                fileName: itemPicture.fileNameShort,
                                type: itemPicture.fileType,
                                deleted: false,
                                src: `data:image/jpg;base64,${itemPicture.file}`
                            });
                        })
                    }

                    await this.checkParams();

                    this.checkAndAddNewRegistersToList();
                }
            },

            async checkParams() {

                if (this.params != null && this.params != undefined && this.params != "" ) {
                    const jsonParams = JSON.parse(this.params);

                    if (jsonParams != null && jsonParams != undefined) {
                        if (jsonParams.showOnlyApprovedItems == true) {
                            this.showHideButtonSections = true;
                            
                            await this.hideAllSections();

                            //Pool Coping
                            let existsProductNeedsApproval = { ...this.colorSelectionRequest.listCoping.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.poolCoping = true;
                            }

                            //Spa
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listSpa.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.spaCoping = true;
                            }

                            //Pool Waterline
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listPoolWaterline.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.poolWaterline = true;
                            }

                            //Pool Cap tile
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listPoolCapTile.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.poolCapTile = true;
                            }

                            //Spa Waterline
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listSpaWaterline.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.spaWaterline = true;
                            }

                            //Spa Cap tile
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listSpaCapTile.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.spaCapTile = true;
                            }

                            //Spa Exterior Wall
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listSpaExteriorWall.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.spaExteriorWall = true;
                            }

                            //Spa Spill Way
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listSpaSpillway.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.spaSpillWay = true;
                            }

                            //Trough Tile
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listTroughTile.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.troughTile = true;
                            }

                            //Front Raised Wall
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listFrontRaisedWall.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.frontRaisedWall = true;
                            }

                            //Sides Raised Wall
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listSidesRaisedWall.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.sidesRaisedWall = true;
                            }

                            //Back Raised Wall
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listBackRaisedWall.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.backRaisedWall = true;
                            }

                            //Front Pedestal
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listFrontPedestal.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.frontPedestal = true;
                            }

                            //Sides Pedestal
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listSidesPedestal.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.sidesPedestal = true;
                            }

                            //Back Pedestal
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listBackPedestal.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.backPedestal = true;
                            }

                            //Top Pedestal
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listTopPedestal.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.topPedestal = true;
                            }

                            //Tile Inserts
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listTileInserts.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.tileInserts = true;
                            }

                            //Mosaics
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listMosaics.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.mosaics = true;
                            }

                            //Scuppers
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listScuppers.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.scuppers = true;
                            }

                            //Table Top
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listTableTop.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.tableTop = true;
                            }

                            //Table Sides
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listTableSides.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.tableSides = true;
                            }

                            //Table Base
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listTableBase.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.tableBase = true;
                            }

                            //Bar Stool Top
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listBarStoolsTop.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.barStoolTop = true;
                            }

                            //Bar Stool Sides
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listBarStoolsSides.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.barStoolSides = true;
                            }

                            //Bar Stool Base
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listBarStoolsBase.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.barStoolBase = true;
                            }

                            //Hand Hold
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listHandholdSelection.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.handHold = true;
                            }

                            //Water Fall Color
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listWaterfallColor.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.waterFallColor = true;
                            }

                            //Four Piece Deck
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listFourPieceDeck.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.fourPieceDeck = true;
                            }

                            //Deck 6x12
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listDeck6x12.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.deck6x12 = true;
                            }

                            //Deck 12x24
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listDeck12x24.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.deck12x24 = true;
                            }

                            //Deck 16x24
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listDeck16x24.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.deck16x24 = true;
                            }

                            //Retaining Wall Back Material
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listRetainingWallBackMaterial.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.retainingWallBackMaterial = true;
                            }

                            //Interior Pool Finish
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listInteriorPoolFinish.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.interiorPoolFinish = true;
                            }

                            //Column Sides
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listColumnsSides.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.columnSides = true;
                            }

                            //Column Top
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listColumnsTop.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.columnTop = true;
                            }

                            //Fire Pit Top Material
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listFirePitTopMaterial.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.firePitTopMaterial = true;
                            }

                            //Fire Pit Sides Material
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listFirePitSidesMaterial.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.firePitSidesMaterial = true;
                            }

                            //Fire Pit Back Material
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listFirePitBackMaterial.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.firePitBackMaterial = true;
                            }

                            //Fire Pit Front Material
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listFirePitFrontMaterial.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.firePitFrontMaterial = true;
                            }

                            //BBQ Top Material
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listBBQTopMaterial.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.bbqTopMaterial = true;
                            }

                            //BBQ Sides Material
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listBBQSidesMaterial.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.bbqSidesMaterial = true;
                            }

                            //BBQ Back Material
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listBBQBackMaterial.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.bbqBackMaterial = true;
                            }

                            //BBQ Front Material
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listBBQFrontMaterial.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.bbqFrontMaterial = true;
                            }

                            //BBQ Black Splash Material
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listBBQBlackSplashMaterial.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.bbqBlackSplashMaterial = true;
                            }

                            //Bowls Color
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listBowlsColor.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.bowlsColor = true;
                            }

                            //Baby Fence
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listBabyFence.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.babyFence = true;
                            }

                            //Perimiter Fence
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listPerimeterFence.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.perimiterFence = true;
                            }

                            //Pergola
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listPergola.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.pergola = true;
                            }

                            //Fitting Color
                            existsProductNeedsApproval = { ...this.colorSelectionRequest.listFittingColor.filter(item => item.needsApproval == true && item.approved == false)[0] };
                            if (existsProductNeedsApproval != null && existsProductNeedsApproval != undefined && existsProductNeedsApproval.needsApproval == true) {
                                this.showSections.fittingColor = true;
                            }
                        }
                    }
                }

            },

            async showAllSections() {
                this.showSections = {
                    poolCoping: true,
                    spaCoping: true,
                    poolWaterline: true,
                    poolCapTile: true,
                    spaWaterline: true,
                    spaCapTile: true,
                    spaExteriorWall: true,
                    spaSpillWay: true,
                    troughTile: true,
                    frontRaisedWall: true,
                    sidesRaisedWall: true,
                    backRaisedWall: true,
                    frontPedestal: true,
                    sidesPedestal: true,
                    backPedestal: true,
                    topPedestal: true,
                    tileInserts: true,
                    mosaics: true,
                    scuppers: true,
                    tableTop: true,
                    tableSides: true,
                    tableBase: true,
                    barStoolTop: true,
                    barStoolSides: true,
                    barStoolBase: true,
                    handHold: true,
                    waterFallColor: true,
                    fourPieceDeck: true,
                    deck6x12: true,
                    deck12x24: true,
                    deck16x24: true,
                    retainingWallBackMaterial: true,
                    interiorPoolFinish: true,
                    columnSides: true,
                    columnTop: true,
                    firePitTopMaterial: true,
                    firePitSidesMaterial: true,
                    firePitBackMaterial: true,
                    firePitFrontMaterial: true,
                    bbqTopMaterial: true,
                    bbqSidesMaterial: true,
                    bbqBackMaterial: true,
                    bbqFrontMaterial: true,
                    bbqBlackSplashMaterial: true,
                    bowlsColor: true,
                    babyFence: true,
                    perimiterFence: true,
                    pergola: true,
                    fittingColor: true
                }
            },

            async hideAllSections() {
                this.showSections = {
                    poolCoping: false,
                    spaCoping: false,
                    poolWaterline: false,
                    poolCapTile: false,
                    spaWaterline: false,
                    spaCapTile: false,
                    spaExteriorWall: false,
                    spaSpillWay: false,
                    troughTile: false,
                    frontRaisedWall: false,
                    sidesRaisedWall: false,
                    backRaisedWall: false,
                    frontPedestal: false,
                    sidesPedestal: false,
                    backPedestal: false,
                    topPedestal: false,
                    tileInserts: false,
                    mosaics: false,
                    scuppers: false,
                    tableTop: false,
                    tableSides: false,
                    tableBase: false,
                    barStoolTop: false,
                    barStoolSides: false,
                    barStoolBase: false,
                    handHold: false,
                    waterFallColor: false,
                    fourPieceDeck: false,
                    deck6x12: false,
                    deck12x24: false,
                    deck16x24: false,
                    retainingWallBackMaterial: false,
                    interiorPoolFinish: false,
                    columnSides: false,
                    columnTop: false,
                    firePitTopMaterial: false,
                    firePitSidesMaterial: false,
                    firePitBackMaterial: false,
                    firePitFrontMaterial: false,
                    bbqTopMaterial: false,
                    bbqSidesMaterial: false,
                    bbqBackMaterial: false,
                    bbqFrontMaterial: false,
                    bbqBlackSplashMaterial: false,
                    bowlsColor: false,
                    babyFence: false,
                    perimiterFence: false,
                    pergola: false,
                    fittingColor: false
                }
            },

            async updateProject(projectSelected, listProjects) {

                this.projectSelected = projectSelected;
                this.listProjects = listProjects;

                if (this.projectSelected != null && this.projectSelected != undefined) {
                    this.getColorSelection();
                }
            },
            
            async deleteItem(index, list) {
                list.splice(index, 1);
                
                let lastIndex = list.length - 1;

                list[lastIndex].showAddNewItem = true;
            },

            async addNewItem(itemAtual, list) {

                if (itemAtual !== null && itemAtual !== undefined) {
                    itemAtual.showAddNewItem = false;
                }

                list.push({
                    needsApproval: false,
                    showAddNewItem: true
                });
            },

            async uploadSignedColorSelection() {
                
                const idServiceProject = this.getIDProject();

                this.dialogUploadFile = {
                    show: true,
                    idKey: idServiceProject,
                    reload: Math.random(),
                    acceptType: ".pdf",
                    typeAttachment: TYPE_ATTACHMENT.COLOR_SELECTION.value,
                    typeDocument: TYPE_DOCUMENT.COLOR_SELECTION.value,
                    multipleFiles: false,
                    showMsgConfirmation: true,
                    msgConfirmation: "You are about to upload the signed color selection to the system, do you confirm this decision?",
                    methodAfterUpload: this.uploadColorSelectionSuccess,
                };
            },

            async uploadColorSelectionSuccess() {
                
                this.showLoading();
                
                let result = await this.$store.dispatch("serviceModule/UpdateColorSelectionSigned", this.colorSelectionRequest.id);

                if (result.success) {
                    this.showToast("success", "Success!", "Color Selection updated with successfully");
                }
                else {
                    this.showToast("error", "Error!", result.message);
                }
                
                await this.cancel();

                this.hideLoading();
            },
            
            async showItemNote(item) {
                this.idServiceProjectColorSelectionItem = item.id;
                this.itemDescription = "Notes";
                this.itemNote = item;
                this.note = item.note;
                this.showDialogItemNote = true;
            },

            async saveItemNote() {

                this.itemNote.note = this.note;
                this.showDialogItemNote = false;
            },
            
            async confirmPhotoDelete(itemPictureToDelete) {

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: `You will DELETE this image [${itemPictureToDelete.fileName}], confirm your decision?`,
                    methodConfirm: () => { itemPictureToDelete.deleted = true; },
                    params: 0
                };
            },

            async uploadPictures(id, idKey, file, typeDocument, deleted = false) {

                let attachmentRequest = {
                    id: id,
                    idKey: idKey,
                    file: file ? file : new Blob(),
                    fileName: file ? file.name : "",
                    fileType: file ? file.type : "",
                    fileSize: file ? file.size : 0,
                    deleted: deleted ? deleted : false,
                    typeAttachment: TYPE_ATTACHMENT.COLOR_SELECTION.value,
                    typeDocument: typeDocument,
                }

                if (attachmentRequest.fileName != null && attachmentRequest.fileName != undefined) {
                    let attachmentFileRequest = new FormData();

                    attachmentFileRequest.append("attachmentRequest", JSON.stringify(attachmentRequest));
                    attachmentFileRequest.append("attachmentFile", attachmentRequest.file, attachmentRequest.fileName);

                    const result = await this.$store.dispatch("attachmentModule/CreateUpdate", { 
                        attachmentFileRequest, 
                        id: 0
                    });

                    return result;
                }
                else {
                    return {
                        success: true
                    };
                }
            },

            async savePictures() {

                let allResult = []

                this.colorSelectionPictures.forEach(async itemPicture => {

                    let result = await this.uploadPictures(itemPicture.id, this.colorSelectionRequest.id, itemPicture.file, TYPE_DOCUMENT.COLOR_SELECTION_PICTURES.value, itemPicture.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.COLOR_SELECTION_PICTURES.description,
                            message: result.message
                        })
                    }
                })

                if (allResult.length > 0) {
                    let message = ""

                    await allResult.forEach(itemResult => {
                        message += `Error to upload pictures: [${itemResult.message}] \n`;
                    })

                    this.showToast("error", "Warning!", message);

                    return false;
                }
                else {
                    return true;
                }
            },

            async checkProductValidation() {

                let listProductsNeedsApproval = '';

                //Pool Coping
                await this.colorSelectionRequest.listCoping.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Pool Coping - Item ${item.item} <br />`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Spa
                await this.colorSelectionRequest.listSpa.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Spa Coping - Item ${item.item} <br />`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Pool Waterline
                await this.colorSelectionRequest.listPoolWaterline.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Pool Waterline - Item ${item.item} <br />`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Pool Cap tile
                await this.colorSelectionRequest.listPoolCapTile.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Pool Cap tile - Item ${item.item} <br />`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Spa Waterline
                await this.colorSelectionRequest.listSpaWaterline.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Spa Waterline - Item ${item.item} <br />`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Spa Cap tile
                await this.colorSelectionRequest.listSpaCapTile.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Spa Cap tile - Item ${item.item} <br />`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Spa Exterior Wall
                await this.colorSelectionRequest.listSpaExteriorWall.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Spa Exterior Wall - Item ${item.item} <br />`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Spa Spill Way
                await this.colorSelectionRequest.listSpaSpillway.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Spa Spill Way - Item ${item.item} <br />`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Trough Tile
                await this.colorSelectionRequest.listTroughTile.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Trough Tile - Item ${item.item} <br />`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Front Raised Wall
                await this.colorSelectionRequest.listFrontRaisedWall.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Front Raised Wall - Item ${item.item} <br />`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Sides Raised Wall
                await this.colorSelectionRequest.listSidesRaisedWall.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Sides Raised Wall - Item ${item.item} <br />`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Back Raised Wall
                await this.colorSelectionRequest.listBackRaisedWall.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Back Raised Wall - Item ${item.item} <br />`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Front Pedestal
                await this.colorSelectionRequest.listFrontPedestal.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Front Pedestal - Item ${item.item} <br />`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Sides Pedestal
                await this.colorSelectionRequest.listSidesPedestal.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Sides Pedestal - Item ${item.item} <br />`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Back Pedestal
                await this.colorSelectionRequest.listBackPedestal.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Back Pedestal - Item ${item.item} <br />`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Top Pedestal
                await this.colorSelectionRequest.listTopPedestal.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Top Pedestal - Item ${item.item} <br />`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Tile Inserts
                await this.colorSelectionRequest.listTileInserts.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Tile Inserts - Item ${item.item} <br />`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Mosaics
                await this.colorSelectionRequest.listMosaics.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Mosaics - Item ${item.item} <br />`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Scuppers
                await this.colorSelectionRequest.listScuppers.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Scuppers - Item ${item.item} <br />`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Table Top
                await this.colorSelectionRequest.listTableTop.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Table Top - Item ${item.item} <br />`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Table Sides
                await this.colorSelectionRequest.listTableSides.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Table Sides - Item ${item.item} <br />`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Table Base
                await this.colorSelectionRequest.listTableBase.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Table Base - Item ${item.item} <br />`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Bar Stools Top
                await this.colorSelectionRequest.listBarStoolsTop.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Bar Stool Top - Item ${item.item} <br />`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Bar Stools Sides
                await this.colorSelectionRequest.listBarStoolsSides.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Bar Stool Sides - Item ${item.item} <br />`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Bar Stools Base
                await this.colorSelectionRequest.listBarStoolsBase.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Bar Stool Base - Item ${item.item} <br />`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Hand Hold
                await this.colorSelectionRequest.listHandholdSelection.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Hand Hold - Item ${item.item} <br />`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Water Fall Color
                await this.colorSelectionRequest.listWaterfallColor.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Water Fall Color - Item ${item.item} <br />`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Four Piece Deck
                await this.colorSelectionRequest.listFourPieceDeck.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Four Piece Deck - Item ${item.item} </ br>`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Deck 6x12
                await this.colorSelectionRequest.listDeck6x12.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Deck 6x12 - Item ${item.item} </ br>`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Deck 12x24
                await this.colorSelectionRequest.listDeck12x24.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Deck 12x24 - Item ${item.item} </ br>`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Deck 16x24
                await this.colorSelectionRequest.listDeck16x24.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Deck 16x24 - Item ${item.item} </ br>`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Retaining Wall Back Material
                await this.colorSelectionRequest.listRetainingWallBackMaterial.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Retaining Wall Back Material - Item ${item.item} </ br>`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Interior Pool Finish
                await this.colorSelectionRequest.listInteriorPoolFinish.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Interior Pool Finish - Item ${item.item} </ br>`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Column Sides
                await this.colorSelectionRequest.listColumnsSides.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Column Sides - Item ${item.item} </ br>`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Column Top
                await this.colorSelectionRequest.listColumnsTop.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Column Top - Item ${item.item} </ br>`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Fire Pit Top Material
                await this.colorSelectionRequest.listFirePitTopMaterial.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Fire Pit Top Material - Item ${item.item} </ br>`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Fire Pit Sides Material
                await this.colorSelectionRequest.listFirePitSidesMaterial.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Fire Pit Sides Material - Item ${item.item} </ br>`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Fire Pit Back Material
                await this.colorSelectionRequest.listFirePitBackMaterial.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Fire Pit Back Material - Item ${item.item} </ br>`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Fire Pit Front Material
                await this.colorSelectionRequest.listFirePitFrontMaterial.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Fire Pit Front Material - Item ${item.item} </ br>`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //BBQ Top Material
                await this.colorSelectionRequest.listBBQTopMaterial.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `BBQ Top Material - Item ${item.item} </ br>`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //BBQ Sides Material
                await this.colorSelectionRequest.listBBQSidesMaterial.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `BBQ Sides Material - Item ${item.item} </ br>`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //BBQ Back Material
                await this.colorSelectionRequest.listBBQBackMaterial.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `BBQ Back Material - Item ${item.item} </ br>`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //BBQ Front Material
                await this.colorSelectionRequest.listBBQFrontMaterial.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `BBQ Front Material - Item ${item.item} </ br>`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //BBQ Black Splash Material
                await this.colorSelectionRequest.listBBQBlackSplashMaterial.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `BBQ Black Splash Material - Item ${item.item} </ br>`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Bowls Color
                await this.colorSelectionRequest.listBowlsColor.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `BBQ Bowls Color Material - Item ${item.item} </ br>`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Baby Fence
                await this.colorSelectionRequest.listBabyFence.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `BBQ Baby Fence Material - Item ${item.item} </ br>`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Perimiter Fence
                await this.colorSelectionRequest.listPerimeterFence.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Perimiter Fence - Item ${item.item} </ br>`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Pergola
                await this.colorSelectionRequest.listPergola.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Pergola - Item ${item.item} </ br>`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                //Fitting Color
                await this.colorSelectionRequest.listFittingColor.forEach(item => {
                    if (item.productSelected != null && item.productSelected != undefined && item.productSelected.id == 3) {

                        if (item.approved == null || 
                            item.approved == undefined || 
                            item.approved == true) 
                        {
                            item.needsApproval = true;
                            item.approved = false;
                            item.upgrade = "0.00";
                            item.notifyResponsible = false;
                            listProductsNeedsApproval += `Fitting Color - Item ${item.item} </ br>`;
                        }
                        else {
                            item.approved = true;
                            item.notifyResponsible = true;
                        }
                    }
                })

                return listProductsNeedsApproval;
            },

            async validate() {

                const listProductsNeedsApproval = await this.checkProductValidation();

                if (listProductsNeedsApproval.length > 0) {
                    this.dialog = {
                        show: true,
                        headerText: 'Confirmation',
                        bodyText: `The sections below needs approve from admin: <br />${listProductsNeedsApproval}`,
                        methodConfirm: this.save,
                        params: 0
                    };
                }
                else {
                    this.save();
                }
            },

            async save(silent = false){
                
                this.loading = true;
                this.showLoading();

                const idServiceProject = this.getIDProject();

                const newColorSelectionRequest = {
                    id: this.colorSelectionRequest.id,
                    idServiceProject,
                    coping: JSON.stringify(this.colorSelectionRequest.listCoping),
                    spa: JSON.stringify(this.colorSelectionRequest.listSpa),
                    poolWaterline: JSON.stringify(this.colorSelectionRequest.listPoolWaterline),
                    poolCapTile: JSON.stringify(this.colorSelectionRequest.listPoolCapTile),
                    spaWaterline: JSON.stringify(this.colorSelectionRequest.listSpaWaterline),
                    spaCapTile: JSON.stringify(this.colorSelectionRequest.listSpaCapTile),
                    spaExteriorWall: JSON.stringify(this.colorSelectionRequest.listSpaExteriorWall),
                    spaSpillway: JSON.stringify(this.colorSelectionRequest.listSpaSpillway),
                    troughTile: JSON.stringify(this.colorSelectionRequest.listTroughTile),
                    frontRaisedWall: JSON.stringify(this.colorSelectionRequest.listFrontRaisedWall),
                    sidesRaisedWall: JSON.stringify(this.colorSelectionRequest.listSidesRaisedWall),
                    backRaisedWall: JSON.stringify(this.colorSelectionRequest.listBackRaisedWall),
                    frontPedestal: JSON.stringify(this.colorSelectionRequest.listFrontPedestal),
                    sidesPedestal: JSON.stringify(this.colorSelectionRequest.listSidesPedestal),
                    backPedestal: JSON.stringify(this.colorSelectionRequest.listBackPedestal),
                    topPedestal: JSON.stringify(this.colorSelectionRequest.listTopPedestal),
                    tileInserts: JSON.stringify(this.colorSelectionRequest.listTileInserts),
                    mosaics: JSON.stringify(this.colorSelectionRequest.listMosaics),
                    scuppers: JSON.stringify(this.colorSelectionRequest.listScuppers),
                    tableTop: JSON.stringify(this.colorSelectionRequest.listTableTop),
                    tableSides: JSON.stringify(this.colorSelectionRequest.listTableSides),
                    tableBase: JSON.stringify(this.colorSelectionRequest.listTableBase),
                    barStoolsTop: JSON.stringify(this.colorSelectionRequest.listBarStoolsTop),
                    barStoolsSides: JSON.stringify(this.colorSelectionRequest.listBarStoolsSides),
                    barStoolsBase: JSON.stringify(this.colorSelectionRequest.listBarStoolsBase),
                    handholdSelection: JSON.stringify(this.colorSelectionRequest.listHandholdSelection),
                    waterfallColor: JSON.stringify(this.colorSelectionRequest.listWaterfallColor),
                    fourPieceDeck: JSON.stringify(this.colorSelectionRequest.listFourPieceDeck),
                    deck6x12: JSON.stringify(this.colorSelectionRequest.listDeck6x12),
                    deck12x24: JSON.stringify(this.colorSelectionRequest.listDeck12x24),
                    deck16x24: JSON.stringify(this.colorSelectionRequest.listDeck16x24),
                    retainingWallBackMaterial: JSON.stringify(this.colorSelectionRequest.listRetainingWallBackMaterial),
                    interiorPoolFinish: JSON.stringify(this.colorSelectionRequest.listInteriorPoolFinish),
                    columnsSides: JSON.stringify(this.colorSelectionRequest.listColumnsSides),
                    columnsTop: JSON.stringify(this.colorSelectionRequest.listColumnsTop),
                    firePitTopMaterial: JSON.stringify(this.colorSelectionRequest.listFirePitTopMaterial),
                    firePitSidesMaterial: JSON.stringify(this.colorSelectionRequest.listFirePitSidesMaterial),
                    firePitBackMaterial: JSON.stringify(this.colorSelectionRequest.listFirePitBackMaterial),
                    firePitFrontMaterial: JSON.stringify(this.colorSelectionRequest.listFirePitFrontMaterial),
                    bbqTopMaterial: JSON.stringify(this.colorSelectionRequest.listBBQTopMaterial),
                    bbqSidesMaterial: JSON.stringify(this.colorSelectionRequest.listBBQSidesMaterial),
                    bbqBackMaterial: JSON.stringify(this.colorSelectionRequest.listBBQBackMaterial),
                    bbqFrontMaterial: JSON.stringify(this.colorSelectionRequest.listBBQFrontMaterial),
                    bbqBlackSplashMaterial: JSON.stringify(this.colorSelectionRequest.listBBQBlackSplashMaterial),
                    bowlsColor: JSON.stringify(this.colorSelectionRequest.listBowlsColor),
                    babyFence: JSON.stringify(this.colorSelectionRequest.listBabyFence),
                    perimeterFence: JSON.stringify(this.colorSelectionRequest.listPerimeterFence),
                    pergola: JSON.stringify(this.colorSelectionRequest.listPergola),
                    fittingColor: JSON.stringify(this.colorSelectionRequest.listFittingColor),
                }

                const result = await this.$store.dispatch("serviceModule/CreateUpdateColorSelection", newColorSelectionRequest);

                if (result.success === true) {

                    this.colorSelectionRequest.id = result.id;

                    if (await this.savePictures()) {
                        if (silent == false) {
                            this.showToast("success", "Success!", result.message);
                            this.cancel();
                        }
                    }
                }
                else {
                    this.showToast("error", "Warning!", result.message);
                }

                this.loading = false;
                this.hideLoading();
            },

            async downloadColorSelection() {
                await this.save(true);
                this.downloadFile(URL_ATTACHMENT_COLOR_SELECTION, this.getIDProject(), 'application/pdf', 'ColorSelection.pdf')
            },

            async showDialogDocusign(typeDocument) {

                const customerSelected = this.serviceRequest.customerSelected;

                let customer = {
                    name: '',
                    email: ''
                }

                if (customerSelected != null && customerSelected != undefined) {
                    customer.name = customerSelected.name;
                }
                customer.email = this.serviceRequest.email;

                this.dialogDocusign = {
                    show: true,
                    idAux: Math.random(),
                    id: await this.getIDProject(),
                    typeDocument,
                    customer,
                    methodConfirm: () => {
                        this.getColorSelection();
                    }
                }
            },
        },

        async created() {
            this.showLoading();

            await this.getLists();
            await this.getRegister();

            this.hideLoading();
        }
    })
</script>

<style scoped>

    .v-btnCustom {
        height: 45px !important;
        width: 150px;
    }

    .colButtonList {
        padding-top: 12px;    
    }

    @media only screen and (max-width: 647px) {
        .colButtonList {
            margin-top: -30px;
            display: flex;
            align-items: center;
        }

        .btnActions {
            margin-bottom: 5px;
        }
    }
</style>